<template>
  <div class="outer-page">
    <x-table
      :no-data-text="CA('product_check') ? '暂无数据' : '暂无数据查看权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>
    <Modal
      v-model="modal.show"
      :title="modal.title"
      :width="450"
      @on-visible-change="modalChange"
    >
      <Form ref="form" :model="form" :label-width="100" :rules="rules">
        <FormItem label="产品名称" prop="name">
          <Input
            clearable
            v-model="form.name"
            style="width: 260px"
            placeholder="请输入产品名称"
          ></Input>
        </FormItem>
        <FormItem label="产品分类" prop="catId">
          <Select
            clearable
            v-model="form.catId"
            style="width: 260px"
            @on-change="selCat"
          >
            <Option
              v-for="(item, index) in catData"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="产品描述">
          <Input
            clearable
            v-model="form.description"
            style="width: 260px"
            type="textarea"
            placeholder="请输入产品描述"
            :autosize="{ minRows: 2 }"
          ></Input>
        </FormItem>
        <FormItem label="备注">
          <Input
            clearable
            v-model="form.remark"
            style="width: 260px"
            type="textarea"
            placeholder="请输入备注"
            :autosize="{ minRows: 2 }"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      table: {
        columns: [
          {
            title: "产品名称",
            key: "name",
            minWidth: 200,
          },
          {
            title: "描述",
            key: "description",
          },
          {
            title: "产品类别",
            key: "catName",
          },
          {
            title: "备注",
            width: 260,
            key: "remark",
          },
          {
            title: "操作",
            width: 260,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("product_update") && (
                    <a
                      style="margin-right:20px"
                      on-click={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("product_delete") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.delete(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      modal: {
        title: "",
        show: false,
        submitLoading: false,
      },
      form: {
        id: "",
        name: "",
        catId: "",
        description: "",
        remark: "",
        type: "",
        typeName: "",
      },
      rules: {
        name: [{ required: true, message: "请输入产品名称！" }],
        catId: [{ required: true, message: "请选择产品类型！" }],
      },
      catData: [],
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增产品",
            ca: "product_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "产品名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            {
              conditionName: "产品分类",
              component: "select",
              field: "catId",
              data: this.catData,
              defaultValue: "",
              parameterField: "id",
              showField: "name",
            },
          ],
        },
        page: this.page,
      };
      return config;
    },
  },
  methods: {
    getList() {
      if (!this.CA("product_check")) return;
      this.table.loading = true;
      this.$post(this.$api.PRODUCT_INFO.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    //产品类别
    getCategory() {
      this.$post(this.$api.PRODUCT_CATEGORY.LIST).then((res) => {
        this.catData = res.list;
      });
    },
    search(value) {
      this.page.pageNo = 1;
      this.search_data = value;
      this.getList();
    },
    add() {
      this.modal = {
        show: true,
        title: "新增产品",
        submitLoading: false,
      };
    },
    selCat(val) {
      if (!val) return;
      let n = this.catData.filter((item) => item.id == val)[0];
      this.form.type = n.type;
      this.form.typeName = n.typeName;
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.modal = {
        show: true,
        title: "编辑产品",
        submitLoading: false,
      };
    },
    delete(id) {
      this.$post(this.$api.PRODUCT_INFO.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功！");
        this.getList();
      });
    },
    submit() {
      let params = { ...this.form };
      let url;
      if (params.id) {
        url = this.$api.PRODUCT_INFO.UPDATE;
      } else {
        url = this.$api.PRODUCT_INFO.ADD;
      }
      this.modal.submitLoading = true;
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.$post(url, params)
          .then(() => {
            this.$Message.success(params.id ? "修改成功！" : "增加成功！");
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getList()
    },
    modalChange(visible) {
      if (visible) return;
      this.form = {
        id: "",
        name: "",
        catId: "",
        description: "",
        remark: "",
        type: "",
        typeName: "",
      };
      this.$refs.form.resetFields();
    },
  },
  mounted() {
    this.getList();
    this.getCategory();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>